import React, { useContext, useState } from 'react';
import { Button, Grid, Typography, IconButton, InputAdornment } from '@mui/material';
import './LoginForm.css';
import useLogin from '../../../hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import StyledTextField from '../../common/styledComponents/StyledTextField';
import { AuthContext } from '../../../context/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const LoginForm = ({ handleSwitchToRegister, handleSwitchToForgotPassword, onLoginSuccess }) => {
  const { login: loginContext } = useContext(AuthContext);
  const { login, error, loading } = useLogin();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validationSchema = yup.object({
    username: yup.string().required('Username or Email is required'),
    password: yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const authToken = await login(values.username, values.password);
        if (authToken) {
          loginContext(authToken);
          onLoginSuccess();
        } else {
          setErrorMessage('error');
        }
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <form className="auth-form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTextField
            name="username"
            id="username"
            label="Username or Email"
            fullWidth
            required
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label="Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className="text-button auth-links">
          <Button onClick={handleSwitchToForgotPassword} className="announcement to-right-button text-button">Forgot Password?</Button>
        </Grid>
        <Grid item xs={12}>
          <Button className="primary-button" type="submit" fullWidth disabled={loading || formik.isSubmitting}>
            {loading || formik.isSubmitting ? 'Logging in...' : 'Login'}
          </Button>
        </Grid>
        <Grid item xs={12} className="auth-links">
          <span className="regular-text">Don't have an account? </span>
          <Button onClick={handleSwitchToRegister} className="announcement text-button-link text-button">Register now</Button>
        </Grid>
        {errorMessage && (
          <Grid item xs={12} className="error-message">
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        )}
        {error && (
          <Grid item xs={12} className="error-message">
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default LoginForm;
