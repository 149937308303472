import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import Header from '../../components/common/header/Header';
import './AboutUsPage.css';
import VisionImage from '../../assets/images/vision.jpg'; // Replace with the actual path to the image
import MissionImage from '../../assets/images/mission.jpg'; // Replace with the actual path to the image
import Logo from '../../assets/images/logo.png';

const AboutUsPage = () => {
  return (
    <>
      <Header forceScrolled={true} />
      <div className="about-us-page-container">
        <Paper className="about-us-content" elevation={0}>
          <img src={Logo} alt="" className="d-flex m-auto" width={350}/>
          {/* <Typography variant="h4" className="about-us-title primary-text announcement">EDUWaves</Typography> */}
          <Typography variant="h4" className="about-us-title announcement">Educational Consultancy</Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6} className="text-section">
              <Typography variant="h5" className="section-title announcement">Our Vision</Typography>
              <Typography variant="body1" className="section-text">
                "To revolutionize education by providing innovative, accessible, and high-quality learning experiences that empower every learner to reach their fullest potential and contribute meaningfully to a global society."
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className="image-section">
              <img src={MissionImage} alt="Vision" className="section-image" />
            </Grid>

            <Grid item xs={12} md={6} className="image-section">
              <img src={VisionImage} alt="Mission" className="section-image" />
            </Grid>
            <Grid item xs={12} md={6} className="text-section">
              <Typography variant="h5" className="section-title announcement">Our Mission</Typography>
              <Typography variant="body1" className="section-text">
                "Our mission is to deliver exceptional education services through cutting-edge technology, personalized learning, and dedicated support. We strive to foster a love for learning, cultivate critical thinking, and inspire lifelong success in students of all ages and backgrounds. By partnering with educators, institutions, and communities, we aim to break down barriers to education and create a brighter, more inclusive future for all.”
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default AboutUsPage;
