import React from 'react';
import { Typography, Grid } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ComputerIcon from '@mui/icons-material/Computer';
import FeatureCard from '../../common/featureCard/FeatureCard';
import './FeaturesSection.css';

const FeaturesSection = () => {
  const features = [
    {
      icon: SchoolIcon,
      title: 'Expert Instructors',
      text: 'Learn from industry professionals with years of experience.',
    },
    {
      icon: ScheduleIcon,
      title: 'Flexible Schedule',
      text: 'Choose the timing that suits you best.',
    },
    {
      icon: ComputerIcon,
      title: 'Interactive Learning',
      text: 'Engage with live sessions and practical exercises.',
    },
  ];

  return (
    <div className="features-section">
      <Typography variant="h4" className="features-title announcement">Why Choose Us?</Typography>
      <Grid container spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <FeatureCard icon={feature.icon} title={feature.title} text={feature.text} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FeaturesSection;
