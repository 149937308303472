import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton, Paper, Button, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/common/header/Header';
import CoverImage from '../../components/common/coverImage/CoverImage';
import CoverImagePath from '../../assets/images/cover_image2.jpg';
import StyledTextField from '../../components/common/styledComponents/StyledTextField';
import useAxios from '../../hooks/useAxios';
import AlertModal from '../../components/common/alertModal/AlertModal';
import './ContactUsPage.css';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  phone: yup.string().required('Phone Number is required').max(20),
  email: yup.string().email('Invalid email address').required('Email is required'),
  message: yup.string().required('Message is required'),
});

const ContactUsPage = () => {
  const { postRequest } = useAxios();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await postRequest('/contact', values);
        setAlertTitle('Success');
        setAlertMessage('Thank you for reaching out, we will be in touch soon');
        setAlertType('success');
        setAlertOpen(true);
        resetForm();
      } catch (error) {
        setAlertTitle('Error');
        setAlertMessage(error.message || 'Failed to send your message. Please try again.');
        setAlertType('error');
        setAlertOpen(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Header forceScrolled={true} />
      <CoverImage image={CoverImagePath} />
        <Typography className="contact-intro-text">
          We Look forward to hearing from you
        </Typography>
      <div className="contact-page-container">
        <Paper className="contact-info-container">
          <Box className="contact-info" mb={4}>
            <Typography className="contact-title announcement mb-4">Get in Touch</Typography>
            <div className="contact-info-item">
              <PhoneIcon className="contact-icon" />
              <div>
                <Typography className="contact-subtitle">Phone Numbers</Typography>
                <Typography className="contact-data">00971509904748</Typography>
                <Typography className="contact-data">00971509910248</Typography>
              </div>
            </div>
            <div className="contact-info-item">
              <WhatsAppIcon className="contact-icon" />
              <div>
                <Typography className="contact-subtitle">WhatsApp</Typography>
                <Typography className="contact-data">00971509910248</Typography>
              </div>
            </div>
            <div className="contact-info-item">
              <EmailIcon className="contact-icon" />
              <div>
                <Typography className="contact-subtitle">Email</Typography>
                <Typography className="contact-data">admin@edu-waves.com</Typography>
              </div>
            </div>
            <div className="contact-info-item">
              <InstagramIcon className="contact-icon" />
              <div>
                <Typography className="contact-subtitle">Instagram</Typography>
                <Typography className="contact-data">@eduwaves_2024</Typography>
              </div>
            </div>
          </Box>
          {/* <Box className="social-media-icons" textAlign="center">
            <IconButton href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="social-icon" />
            </IconButton>
            <IconButton href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="social-icon" />
            </IconButton>
            <IconButton href="https://www.indeed.com/" target="_blank" rel="noopener noreferrer">
              <IndeedIcon className="social-icon" />
            </IconButton>
          </Box> */}
        </Paper>

        <Box className="contact-form-container" mb={4}>
          <Typography className="contact-title announcement mb-4">Send Us a Message</Typography>
          <form className="contact-form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Name"
                  name="name"
                  fullWidth
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Phone Number"
                  name="phone"
                  fullWidth
                  required
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Email"
                  name="email"
                  fullWidth
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  className="contact-submit-button announcement"
                  fullWidth
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
        {/* <div className="map-section">
            <Typography variant="h4" className="contact-title announcement mb-4">Pay us a visit</Typography>
            <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.414575238339!2d55.26820787575948!3d25.204849323489987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43021b9fee2b%3A0xc95b1844d631a53b!2sGlobal%20Visa!5e0!3m2!1sen!2sae!4v1691682977465!5m2!1sen!2sae"
            allowFullScreen=""
            loading="lazy"
            title="Global Visa Location"
            ></iframe>

          </div> */}
        <AlertModal
          open={alertOpen}
          onClose={handleAlertClose}
          title={alertTitle}
          message={alertMessage}
          type={alertType}
        />
      </div>
    </>
  );
};

export default ContactUsPage;
