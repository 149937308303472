import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& label': {
    fontFamily: 'BuenosAires, sans-serif',
  },
  '& label.Mui-focused': {
    color: 'var(--dark-purple)',
    fontFamily: 'BuenosAires, sans-serif',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'var(--dark-purple)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderRadius: '12px',
    },
    '&:hover fieldset': {
      borderColor: 'var(--dark-purple)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--dark-purple)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'BuenosAires, sans-serif',
    '&::-webkit-datetime-edit': {
      color: 'transparent',
    },
    '&::-webkit-datetime-edit-fields-wrapper': {
      color: 'transparent',
    },
    '&::-webkit-datetime-edit-text': {
      color: 'transparent',
    },
    '&::-webkit-datetime-edit-month-field': {
      color: 'transparent',
    },
    '&::-webkit-datetime-edit-day-field': {
      color: 'transparent',
    },
    '&::-webkit-datetime-edit-year-field': {
      color: 'transparent',
    },
    '&:not([value=""])': {
      '&::-webkit-datetime-edit': {
        color: 'initial',
      },
      '&::-webkit-datetime-edit-fields-wrapper': {
        color: 'initial',
      },
      '&::-webkit-datetime-edit-text': {
        color: 'initial',
      },
      '&::-webkit-datetime-edit-month-field': {
        color: 'initial',
      },
      '&::-webkit-datetime-edit-day-field': {
        color: 'initial',
      },
      '&::-webkit-datetime-edit-year-field': {
        color: 'initial',
      },
    },
  },
});

const CustomDateTextField = (props) => {
  return (
    <StyledTextField
      {...props}
    />
  );
};

export default CustomDateTextField;
