import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import './AuthenticatedUserIcon.css';

const AuthenticatedUserIcon = () => {
  return (
    <Box position="relative" display="inline-flex">
      <PersonIcon fontSize="large" className="person-icon"/>
      <CheckCircleIcon
        fontSize="small"
        className="tick-icon"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          backgroundColor: 'white',
          borderRadius: '50%'
        }}
      />
    </Box>
  );
}

export default AuthenticatedUserIcon;
