import React from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Slider from 'react-slick';
import CourseCard from '../courseCard/CourseCard';
import './ComingSoonSection.css';
import categories from '../../../assets/data/CategoriesList';


const ComingSoonSection = ({ title, text, courses }) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container className="coming-soon-container">
      <div className="background-overlay"></div>
      <div className="mock-content">
        {/* <Typography variant="h4" className="services-title announcement">Upcoming Services</Typography> */}
        <div className="services-description-container">
          <Typography variant="body1" className="services-description">
            We are working hard to bring you new and exciting services. <span className="highlight">Stay tuned</span> for updates and announcements.
          </Typography>
        </div>
        <Slider {...settings} className="services-slider">
          {courses.map((course, index) => (
            <div key={index} className="slide">
              <CourseCard course={course} />
            </div>
          ))}
        </Slider>
      </div>
      <Grid container spacing={3} className="coming-soon-content">
        <Grid item xs={12} md={12}>
          <div className="coming-soon-info">
            <HourglassEmptyIcon className="coming-soon-icon" />
            <Typography variant="h5" className="coming-soon-title announcement">{title}</Typography>
            <Typography variant="body1" className="coming-soon-text">{text}</Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ComingSoonSection;
