import React, { useState, useContext, useEffect } from 'react';
import { Button, Grid, Typography, IconButton, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './ForgotPasswordForm.css';
import StyledTextField from '../../common/styledComponents/StyledTextField';
import useAxios from '../../../hooks/useAxios';
import { AuthContext } from '../../../context/AuthContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ForgotPasswordForm = ({ handleSwitchToLogin }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [resendCounter, setResendCounter] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { postRequest } = useAxios();
  const [isLoading, setIsLoading] = useState(false); 
  
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const emailFormik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await postRequest('/request-password-reset', values);
        setEmailSubmitted(true);
        setCanResend(false);
        setResendCounter(60); // 1 minute countdown
      } catch (error) {
        console.error('Error requesting password reset', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const passwordFormik = useFormik({
    initialValues: { otp: '', password: '', password_confirmation: '' },
    validationSchema: yup.object({
      otp: yup.string().required('OTP is required'),
      password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
      password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await postRequest('/reset-password', values);
        handleSwitchToLogin();
      } catch (error) {
        console.error('Error resetting password', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (resendCounter > 0) {
      const timer = setInterval(() => {
        setResendCounter((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanResend(true);
    }
  }, [resendCounter]);

  const handleResendOTP = async () => {
    try {
      setIsLoading(true);
      await postRequest('/request-password-reset', { email: emailFormik.values.email });
      setCanResend(false);
      setResendCounter(300); // 5 minutes countdown
      setIsLoading(false);
    } catch (error) {
      console.error('Error resending OTP', error);
    }
  };

  return (
    <form className="auth-form" onSubmit={emailSubmitted ? passwordFormik.handleSubmit : emailFormik.handleSubmit}>
      <Grid container spacing={2} justifyContent="center">
        {!emailSubmitted ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h4" className="form-subtitle">
                Enter your email, we'll send you an OTP code
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Email"
                name="email"
                fullWidth
                required
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                error={emailFormik.touched.email && Boolean(emailFormik.errors.email)}
                helperText={emailFormik.touched.email && emailFormik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Button className="primary-button" type="submit" fullWidth disabled={emailFormik.isSubmitting}>
                {emailFormik.isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h4" className="form-subtitle">
                Please use The OTP, and choose a new password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="OTP"
                name="otp"
                fullWidth
                required
                value={passwordFormik.values.otp}
                onChange={passwordFormik.handleChange}
                error={passwordFormik.touched.otp && Boolean(passwordFormik.errors.otp)}
                helperText={passwordFormik.touched.otp && passwordFormik.errors.otp}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="New Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={passwordFormik.values.password}
                onChange={passwordFormik.handleChange}
                error={passwordFormik.touched.password && Boolean(passwordFormik.errors.password)}
                helperText={passwordFormik.touched.password && passwordFormik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Confirm New Password"
                name="password_confirmation"
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                required
                value={passwordFormik.values.password_confirmation}
                onChange={passwordFormik.handleChange}
                error={passwordFormik.touched.password_confirmation && Boolean(passwordFormik.errors.password_confirmation)}
                helperText={passwordFormik.touched.password_confirmation && passwordFormik.errors.password_confirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button className="primary-button" type="submit" fullWidth disabled={passwordFormik.isSubmitting}>
                {passwordFormik.isSubmitting ? 'Resetting...' : 'Reset Password'}
              </Button>
            </Grid>
            <Grid item xs={12} className="text-center">
              {canResend ? (
                <Button className="text-button announcement m-auto d-flex" disabled={isLoading ? true : false} onClick={handleResendOTP}>{isLoading ? "Resending OTP... " : "Resend OTP"}</Button>
              ) : (
                <Typography variant="body2" className="resend-otp">
                  Resend OTP in {Math.floor(resendCounter / 60)}:{('0' + (resendCounter % 60)).slice(-2)}
                </Typography>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12} className="auth-links">
          <Button onClick={handleSwitchToLogin} className="text-button">Back to Login</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ForgotPasswordForm;
