// src/components/common/header/Header.jsx
import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Button, Menu, MenuItem, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import VerifiedIcon from '@mui/icons-material/Verified';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import Logo from '../../../assets/images/logo.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AuthContext } from '../../../context/AuthContext';
import AuthenticatedUserIcon from '../icons/authenticatedUserIcon/AuthenticatedUserIcon';

const Header = ({ forceScrolled }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [scrolled, setScrolled] = useState(forceScrolled);
  const location = useLocation();
  const { isLoggedIn, logout } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event) => {
    if (window.innerWidth <= 768) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  
  const handlePrfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    if (!forceScrolled) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [forceScrolled]);

  const drawer = (
    <div>
      <img src={Logo} alt="Logo" className="logo m-3" />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/about">
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button onClick={handleMenuOpen}>
          <ListItemText primary="Services" />
          {anchorEl && (
            <List className="submenu">
              <ListItem button component={Link} to="/services/student" onClick={handleDrawerToggle}>
                <ListItemText primary="Student Services" />
              </ListItem>
              <Divider />
              <ListItem button className="disabled">
                <ListItemText primary="Teacher Services" />
                <span className="soon-badge">Soon</span>
              </ListItem>
              <Divider />
              <ListItem button className="disabled">
                <ListItemText primary="School Services" />
                <span className="soon-badge">Soon</span>
              </ListItem>
            </List>
          )}
        </ListItem>
        <ListItem button component={Link} to="/contact">
          <ListItemText primary="Contact Us" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" className={`header ${scrolled ? 'header-scrolled' : ''}`}>
      <Toolbar className="toolbar">
        <div className="left-section">
          <img src={Logo} alt="Logo" className={`logo ${scrolled ? '' : 'logo-hidden'}`} />
          <div className="nav-links">
            <Button component={Link} to="/" color="inherit">Home</Button>
            <Button component={Link} to="/about" color="inherit">About Us</Button>
            <Button
              aria-controls="services-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
              endIcon={<ArrowDropDownIcon />}
              style={{ position: 'relative' }}
            >
              Services
            </Button>
            <Menu
              id="services-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className="menu"
              MenuListProps={{ className: 'custom-menu' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }} // Ensure the menu opens below the button
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }} // Align menu to avoid affecting the button's position
            >
              <MenuItem component={Link} to="/services/student" className="menu-item" onClick={handleMenuClose}>Student Services</MenuItem>
              <Divider />
              <MenuItem className="menu-item" component={Link} to="/services/teacher">
                Teacher Services <span className="soon-badge">Soon</span>
              </MenuItem>
              <Divider />
              <MenuItem className="menu-item" component={Link} to="/services/school">
                School Services <span className="soon-badge">Soon</span>
              </MenuItem>
            </Menu>
            
            <Button component={Link} to="/contact" color="inherit">Contact Us</Button>
          </div>
        </div>
        <div className="right-section">
          {isLoggedIn ? (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="account"
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                className="logged-in-icon"
              >
                <AuthenticatedUserIcon />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={profileAnchorEl}
                open={Boolean(profileAnchorEl)}
                onClose={handlePrfileMenuClose}
                className="menu"
                MenuListProps={{ className: 'custom-menu' }}
              >
                <MenuItem component={Link} to="/profile" className="menu-item disabled" onClick={() => {}}>
                  <AccountCircleIcon style={{ marginRight: '8px' }} />
                  Update Profile <span className="soon-badge">Soon</span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout} className="menu-item">
                  <ExitToAppIcon style={{ marginRight: '8px' }} />
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            location.pathname !== '/auth' && (
              <Button component={Link} to="/auth" className="login-button announcement">Login</Button>
            )
          )}
        </div>
        <IconButton
          edge="start"
          className="menu-button"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className="drawer"
        >
          {drawer}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
