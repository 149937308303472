import { useState } from 'react';
import axios from 'axios';
import config from '../config';

const useLogin = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const login = async (username, password) => {
    setLoading(true);
    setError(null); // Reset error state before making the request
    try {
      const response = await axios.post(`${config.apiBaseUrl}login`, {
        username,
        password
      });

      if (response.data.token) {
        const authToken = `${response.data.type} ${response.data.token}`;
        localStorage.setItem('token', authToken);
        setData(response.data);
        return authToken; // Return the authToken string instead of response.data
      } else {
        throw new Error('Invalid response');
      }
    } catch (err) {
      setError(err.response ? err.response.data.message : 'Login failed');
      return null; 
    } finally {
      setLoading(false);
    }
  };

  return { login, data, error, loading };
};

export default useLogin;
