import { useState } from 'react';
import axios from 'axios';
import config from '../config';

const useAxios = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token'); // Get the token from localStorage

  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }), // Conditionally add the Authorization header if the token exists
    },
  });

  const handleErrors = (err) => {
    if (err.response) {
      const status = err.response.status;
      const data = err.response.data;

      if (status === 400) {
        setError('Bad Request: The server could not understand the request.');
      } else if (status === 401) {
        setError('Unauthorized: Access is denied due to invalid credentials.');
      } else if (status === 403) {
        setError('Forbidden: You do not have permission to access this resource.');
      } else if (status === 404) {
        setError('Not Found: The requested resource could not be found.');
      } else if (status === 422) {
        const validationErrors = data.errors;
        setError(`Validation Error: ${Object.values(validationErrors).join(', ')}`);
      } else if (status >= 500) {
        setError('Server Error: Please try again later.');
      } else {
        setError(data.message || 'An unknown error occurred.');
      }
    } else if (err.request) {
      setError('Network Error: Please check your connection.');
    } else {
      setError('Error: ' + err.message);
    }
  };

  const getRequest = async (url, config = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(url, config);
      return response.data;
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const postRequest = async (url, data, config = {}) => {
    setLoading(true);
    setError(null);
    try {
      const headers = { ...config.headers };

      if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
      }

      const response = await axiosInstance.post(url, data, { ...config, headers });
      return response.data;
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const putRequest = async (url, data, config = {}) => {
    setLoading(true);
    setError(null);
    try {
      const headers = { ...config.headers };

      if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
      }

      const response = await axiosInstance.put(url, data, { ...config, headers });
      return response.data;
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteRequest = async (url, config = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.delete(url, config);
      return response.data;
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = async (url, fileName) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(url, {
        responseType: 'blob', // Important for handling binary data
      });

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', fileName); // Use the provided file name
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(link);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    downloadFile, // Export the downloadFile function
    loading,
    error,
  };
};

export default useAxios;
