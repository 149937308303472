import React from 'react';
import './SchoolServicesPage.css';
import Header from '../../../components/common/header/Header';
import { Container, Typography, List, ListItem, ListItemText } from '@mui/material';
import SchoolServicesList from '../../../assets/data/SchoolServicesList';

const SchoolServicesPage = () => {
    return (
        <>
            <Header forceScrolled={true} />
            <Container className="school-services-container">
                <Typography variant="h4" className="school-services-title">School Services</Typography>
                <Container className="school-services-description">
                    <Typography variant="body1" gutterBottom className="announcement page-description">
                        Our educational consultancy provides a range of services to support schools in achieving their educational goals. Here are some key services we offer:
                    </Typography>
                    <List>
                        {SchoolServicesList.map((service, index) => (
                            <ListItem key={index} className="school-service-item">
                                <ListItemText
                                    primary={<strong className="announcement">{service.title}</strong>}
                                    secondary={service.description}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Typography variant="body1" gutterBottom className="announcement page-footer-text">
                        These services are designed to help schools improve their educational outcomes, streamline operations, and enhance the overall learning environment.
                    </Typography>
                </Container>
            </Container>
        </>
    );
};

export default SchoolServicesPage;
