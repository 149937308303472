
const TeacherServicesList = [
  {
      title: "Professional Development Workshops",
      description: "Training programs focused on modern teaching methodologies, classroom management, curriculum design, and the use of technology in education."
  },
  {
      title: "Curriculum Development Assistance",
      description: "Support in designing and developing curriculum plans that align with educational standards and student needs."
  },
  {
      title: "Mentorship Programs",
      description: "Pairing experienced educators with less experienced teachers for mentorship, providing guidance, feedback, and support in professional growth."
  },
  {
      title: "Classroom Resources and Tools",
      description: "Providing access to educational resources, lesson plans, teaching aids, and technology tools to enhance teaching effectiveness."
  },
  {
      title: "Workshops on Inclusive Education",
      description: "Training on how to effectively teach students with diverse needs, including special education and culturally responsive teaching practices."
  },
  {
      title: "Educational Research and Best Practices",
      description: "Offering insights and updates on the latest research in education, helping teachers stay informed about best practices in their field."
  },
  {
      title: "International Teaching Opportunities",
      description: "Assisting teachers who are interested in teaching abroad by providing information about international certifications, job placements, and cultural adaptation."
  },
  {
      title: "Teacher Well-being and Stress Management",
      description: "Programs focused on mental health, stress management, and work-life balance to help teachers maintain their well-being."
  },
  {
      title: "Technology Integration",
      description: "Training on how to integrate digital tools and e-learning platforms into teaching practices to enhance student engagement and learning outcomes."
  },
  {
    title: "Digital Apps",
    description: ""
  }
];

export default TeacherServicesList;
