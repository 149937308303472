import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './CourseCard.css';

const CourseCard = ({ course, onClick, hasButton, isCentered, image }) => {
  const imgSrc = image ? image : `${process.env.REACT_APP_BASE_URL}/${course.icon}`;

  return (
    <Card className="course-card" onClick={onClick}>
      <CardContent>
        <img
          src={imgSrc}
          alt={`${course.name} icon`}
          className={`${isCentered ? 'course-icon-centered w-100' : 'course-icon-top'} course-image`}
          width={image? 20 : ''}
        />
        <Typography variant="h6" className="course-name text-center">{course.name}</Typography>
        <Typography variant="body2" className="course-description">
          {course.description}
        </Typography>
      </CardContent>
      {hasButton && (
        <Button
          className="learn-more-button announcement"
          component={Link}
          to="#"
        >
          Enroll Now &gt;
        </Button>
      )}
    </Card>
  );
};

export default CourseCard;
