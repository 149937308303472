import React, { useContext, useState, useEffect } from 'react';
import { Button, Grid, Typography, IconButton, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './RegisterForm.css';
import StyledTextField from '../../common/styledComponents/StyledTextField';
import useAxios from '../../../hooks/useAxios';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Define the validation schema using yup
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  nationality: yup.string().required('Nationality is required'),
  phone_number: yup.string().required('Phone Number is required'),
  parent_phone_number: yup.string().required('Parent Phone Number is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required')
});

const RegisterForm = ({ handleSwitchToLogin }) => {
  const { postRequest, loading, error } = useAxios();
  const navigate = useNavigate();
  const [otpStep, setOtpStep] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resendCounter, setResendCounter] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const formik = useFormik({
    initialValues: {
      name: '',
      nationality: '',
      phone_number: '',
      parent_phone_number: '',
      email: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await postRequest('/register', values);
        if (response) {
          setOtpStep(true);
          setCanResend(false);
          setResendCounter(30); // 30 seconds countdown
        }
      } catch (err) {
        console.error('Registration failed', err);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const otpFormik = useFormik({
    initialValues: { otp: '' },
    validationSchema: yup.object({
      otp: yup.string().required('OTP is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await postRequest('/verify-otp', values);
        handleSwitchToLogin();
      } catch (err) {
        console.error('OTP verification failed', err);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleResendOTP = async () => {
    try {
      setIsLoading(true);
      await postRequest('/auth/resend-otp', { email: formik.values.email });
      setCanResend(false);
      setResendCounter(300); // 5 minutes countdown
      setIsLoading(false);
    } catch (error) {
      console.error('Error resending OTP', error);
    }
  };

  useEffect(() => {
    if (resendCounter > 0) {
      const timer = setInterval(() => {
        setResendCounter((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanResend(true);
    }
  }, [resendCounter]);

  return (
    <form className="auth-form" onSubmit={otpStep ? otpFormik.handleSubmit : formik.handleSubmit}>
      <Grid container spacing={2}>
        {!otpStep ? (
          <>
          <div className="text-center m-auto d-flex">
            <Typography className="auth-subtitle">
              Create your account with EDUWaves <span className="colored-text">For Free!</span> 
            </Typography>
          </div>
            <Grid item xs={12}>
              <StyledTextField
                label="Name"
                name="name"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Nationality"
                name="nationality"
                fullWidth
                required
                value={formik.values.nationality}
                onChange={formik.handleChange}
                error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                helperText={formik.touched.nationality && formik.errors.nationality}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Phone Number"
                name="phone_number"
                fullWidth
                required
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Parent Phone Number"
                name="parent_phone_number"
                fullWidth
                required
                value={formik.values.parent_phone_number}
                onChange={formik.handleChange}
                error={formik.touched.parent_phone_number && Boolean(formik.errors.parent_phone_number)}
                helperText={formik.touched.parent_phone_number && formik.errors.parent_phone_number}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Email"
                name="email"
                fullWidth
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Confirm Password"
                name="password_confirmation"
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                required
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="primary-button"
                type="submit"
                fullWidth
                disabled={loading || formik.isSubmitting}
              >
                {loading || formik.isSubmitting ? 'Registering...' : 'Register'}
              </Button>
            </Grid>
            <Grid item xs={12} className="auth-links">
              <span className="regular-text">Already a member? </span>
              <Button onClick={handleSwitchToLogin} className="announcement text-button">Login instead</Button>
            </Grid>
            {error && (
              <Grid item xs={12} className="error-message">
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" className="form-subtitle">
                Please check your email for the OTP. <br /> 
                if Not received, please check your spam/junk <br />
                <span className="announcement colored-text">Don't leave this page until you submit your OTP.</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="OTP"
                name="otp"
                fullWidth
                required
                value={otpFormik.values.otp}
                onChange={otpFormik.handleChange}
                error={otpFormik.touched.otp && Boolean(otpFormik.errors.otp)}
                helperText={otpFormik.touched.otp && otpFormik.errors.otp}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="announcement colored-text text-center">
                didn't receive an email?
              </Typography>
              {canResend ? (
                <Button className="text-button announcement m-auto d-flex" disabled={isLoading ? true : false} onClick={handleResendOTP}>{isLoading ? "Resending OTP... " : "Resend OTP"}</Button>
              ) : (
                <div className="text-center">
                  <Typography variant="body2" className="resend-otp">
                    Resend OTP in {Math.floor(resendCounter / 60)}:{('0' + (resendCounter % 60)).slice(-2)}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                className="primary-button "
                type="submit"
                fullWidth
                disabled={otpFormik.isSubmitting}
              >
                {otpFormik.isSubmitting ? 'Verifying...' : 'Submit OTP'}
              </Button>
            </Grid>
            
          </>
        )}
      </Grid>
    </form>
  );
};

export default RegisterForm;
