import './HomePage.css';
import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import WelcomeSection from '../../components/home/welcomeSection/WelcomeSection';
import FeaturesSection from '../../components/home/featuresSection/FeaturesSection';
import ServicesSection from '../../components/home/servicesSection/ServicesSection';
import ComingSoonSection from '../../components/common/comingSoonSection/ComingSoonSection';
import TopBanner from '../../components/common/topBanner/TopBanner';
import Header from '../../components/common/header/Header';
// import categories from '../../assets/data/CategoriesList';

import topImage from '../../assets/images/cover-image.png';
import teachersBackgroundImage from '../../assets/images/teacher-image.webp';
import schoolBackgroundImage from '../../assets/images/school-image.webp';
import { NextArrow, PrevArrow } from '../../components/common/CustomArrows/CustomArrows'; 
import useAxios from '../../hooks/useAxios';

const HomePage = () => {  
  const { getRequest } = useAxios(); 
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([])
  const [apiError, setApiError] = useState('');


  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true); 
      try {
        const data = await getRequest('/categories');
        if (data) {
          setCategoriesList(data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setApiError('Failed to fetch categories. Please try again later.');
      } finally {
        setIsLoading(false); 
      }
    };

    fetchCategories();
  }, []); 

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const courses = categoriesList.flatMap(category => category.courses);

  return (
    <div>
      {/* <div className="top-image-container">
        <img src={topImage} alt="Top Banner" className="top-image" />
      </div> */}
      <Header forceScrolled={true} />
      <TopBanner />
      <Container className="home-container">
        {/* <WelcomeSection /> */}
        <ServicesSection courses={categoriesList} settings={settings} />
        <FeaturesSection />
        <Typography variant="h4" className="services-title announcement">Teacher's Services</Typography>
        <ComingSoonSection
          title="Coming Soon"
          text="Stay tuned for exciting new services for our teachers. We're working hard to bring you the best experience possible."
          backgroundImage={teachersBackgroundImage}
          settings={settings}
          courses={courses}
        />
        <Typography variant="h4" className="services-title announcement">School's Services</Typography>
        <ComingSoonSection
          title="Coming Soon"
          text="We are excited to introduce new services for our schools. Keep an eye out for our upcoming announcements!"
          backgroundImage={schoolBackgroundImage}
          settings={settings}
          courses={categoriesList}
        />
      </Container>
    </div>
  );
};

export default HomePage;
