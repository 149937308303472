// src/pages/studentServices/StudentServicesPage.jsx
import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../components/common/header/Header';
import CategoryList from '../../../components/services/studentServices/categoryList/CategoryList';
import CourseList from '../../../components/services/studentServices/courseList/CourseList';
import './StudentServicesPage.css';
// import categories from '../../assets/data/CategoriesList';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import OtherServices from '../../../components/services/studentServices/otherServices/OtherServices';
import CoverImage from '../../../components/common/coverImage/CoverImage';
import CoverImagePath from '../../../assets/images/cover_image1.jpg';
import useAxios from '../../../hooks/useAxios';

export default function StudentServicesPage() {
    const location = useLocation();
    const initialCategory = location.state?.initialCategory;
    const targetComponentRef = location.state?.targetRef || null;

    const [selectedCategory, setSelectedCategory] = useState(initialCategory?? null);
    const { getRequest } = useAxios(); 

    const [isLoading, setIsLoading] = useState(false);
    const [categoriesList, setCategoriesList] = useState([])
    const [apiError, setApiError] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
          setIsLoading(true); 
          try {
            const data = await getRequest('/categories');
            if (data) {
              setCategoriesList(data);
            }
          } catch (error) {
            console.error('Error fetching categories:', error);
            setApiError('Failed to fetch categories. Please try again later.');
          } finally {
            setIsLoading(false); 
          }
        };
    
        fetchCategories();
      }, []); 
    

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleBackClick = () => {
        setSelectedCategory(null);
    };

    const targetRef = useRef(null);

    useEffect(() => {
        // Scroll to the target ref if specified
        if (targetComponentRef && targetRef.current) {
            targetRef.current.scrollIntoView({
                behavior: 'smooth', 
                block: 'start', 
            });
        } else {
            
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [targetComponentRef]);

    return (
        <>
            <Header forceScrolled={true} />
            <CoverImage image={CoverImagePath} />
            <div className="student-services-page" ref={targetRef}>
                {selectedCategory ? (
                    <>
                        <CourseList category={selectedCategory} onBackClick={handleBackClick} />
                    </>
                ) : (
                    <>
                        <CategoryList categories={categoriesList} onCategoryClick={handleCategoryClick} />
                        <OtherServices />
                    </>
                )}
            </div>
        </>
    );
}
