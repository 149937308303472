import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import './CourseDetailsModal.css';
import StyledTextField from '../styledComponents/StyledTextField';
import useAxios from '../../../hooks/useAxios';
import AlertModal from '../alertModal/AlertModal';

const CourseDetailsModal = ({ course, open, onClose }) => {
    const [selectedMonth, setSelectedMonth] = useState('');
    const [minMonth, setMinMonth] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');
    const { postRequest } = useAxios();

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const currentYear = currentDate.getFullYear();
        setMinMonth(`${currentYear}-${currentMonth}`);
    }, []);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleSubmit = async () => {
        if (!selectedMonth) {
            setAlertTitle('Error');
            setAlertMessage('Please select a month.');
            setAlertType('error');
            setAlertOpen(true);
            return;
        }

        setLoading(true);
        try {
            const response = await postRequest('/courses/enroll', {
                course_id: course.id,
                preferred_date: selectedMonth
            });

            setAlertTitle('Success');
            setAlertMessage('You have been enrolled successfully. We will be in touch soon on your email.');
            setAlertType('success');
            setAlertOpen(true);
        } catch (error) {
            setAlertTitle('Error');
            setAlertMessage(error.message || 'Failed to enroll in the course. Please try again.');
            setAlertType('error');
            setAlertOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
        if (alertType === 'success') {
            onClose();
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box className="modal-box">
                    <Typography variant="h4" className="modal-title">{course.name}</Typography>
                    <Typography variant="body1" className="modal-description">
                        <div className="text-center my-3 bigger-text">
                            Ready to &nbsp;
                            <strong>
                                advance your skills? <br />
                            </strong>
                        </div>
                        Choose your start date for our flexible training course and &nbsp;
                        <em>
                            learn at your own pace. <br />
                        </em>
                        Enroll now and take control of your education! <br />
                        <div className="text-center my-3 bigger-text">
                            <span className="highlight">
                                Special Offer: Limited Time Only!
                            </span>
                        </div>
                    </Typography>
                    <StyledTextField
                        label="Select Month"
                        type="month"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        className="month-select"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: minMonth } }}
                        fullWidth
                    />
                    <Button onClick={handleSubmit} className="primary-button px-5 announcement" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </Box>
            </Modal>
            <AlertModal
                open={alertOpen}
                onClose={handleAlertClose}
                title={alertTitle}
                message={alertMessage}
                type={alertType}
            />
        </>
    );
};

export default CourseDetailsModal;
