// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import AboutUsPage from './pages/aboutUs/AboutUsPage';
import ContactUsPage from './pages/contactUs/ContactUsPage';
import AuthPage from './pages/auth/AuthPage';
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import StudentServicesPage from './pages/services/studentServices/StudentServicesPage';
import TeacherServicesPage from './pages/services/teacherServices/TeacherServicesPage';
import SchoolServicesPage from './pages/services/schoolServices/SchoolServicesPage';
import ResearchPage from './pages/services/studentServices/research/ResearchPage';
import UniversityRequestsPage from './pages/services/studentServices/universityRequests/UniversityRequestsPage';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Router>
      <div className="app-container">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/services/student" element={<StudentServicesPage />} />
            <Route path="/services/student/research" element={<ResearchPage />} />
            <Route path="/services/student/requests" element={<UniversityRequestsPage />} />
            <Route path="/services/teacher" element={<TeacherServicesPage />} />
            <Route path="/services/school" element={<SchoolServicesPage />} />
            <Route path="/auth" element={<AuthPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
