import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import './FeatureCard.css';

const FeatureCard = ({ icon: Icon, title, text }) => {
  return (
    <Card className="feature-card">
      <CardContent>
        <Icon className="feature-icon" />
        <Typography variant="h6" className="feature-name">{title}</Typography>
        <Typography variant="body2" className="feature-text">{text}</Typography>
      </CardContent>
    </Card>
  );
};

export default FeatureCard;
