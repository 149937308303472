import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import './TopBanner.css';
import Logo from '../../../assets/images/logo.png'; // Adjust the path if necessary

const TopBanner = () => {
  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    // <div className={`top-banner ${scrolled ? 'top-banner-scrolled' : ''}`}>
    <div className={`top-banner`}>
      <Container className="banner-container">
        <div className={`banner-logo`}>
          <img src={Logo} alt="Company Logo" className="logo" />
        </div>
        <div className="banner-text">
          <Typography variant="h3" className="banner-line announcement">Unlock your potential!</Typography>
          <Typography variant="h4" className="banner-line announcement">With Our Comprehensive</Typography>
          <Typography variant="h4" className="banner-line announcement">Courses!</Typography>
        </div>
      </Container>
    </div>
  );
};

export default TopBanner;
