import React from 'react';
import { Typography } from '@mui/material';
import Slider from 'react-slick';
import CourseCard from '../../../common/courseCard/CourseCard';
import './CategoryList.css';
import { NextArrow, PrevArrow } from '../../../common/CustomArrows/CustomArrows'; 

const CategoryList = ({ categories, onCategoryClick }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="category-list-container">
      <div className="page-intro">
        <Typography variant="h4" className="list-title announcement mb-2">Courses Categories</Typography>
      </div>
      <Slider {...settings} className="category-slider">
        {categories.map((category, index) => (
          <div key={index} className="slide">
            <CourseCard isCentered={true} course={category} onClick={() => onCategoryClick(category)} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CategoryList;
