import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAxios from '../../../../hooks/useAxios';
import './ResearchPage.css';
import Header from '../../../../components/common/header/Header';
import { UploadFile } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../../../components/common/alertModal/AlertModal';
import StyledTextField from '../../../../components/common/styledComponents/StyledTextField';
import CustomDateTextField from '../../../../components/common/styledComponents/StyledDateField';

// Define the validation schema using yup
const validationSchema = yup.object({
  student_name: yup.string().required('Student Name is required'),
  subject: yup.string().required('Subject is required'),
  student_phone_number: yup.string().required('Student Phone Number is required'),
  university_name: yup.string().required('University Name is required'),
  due_date: yup.string().required('Due Date is required'),
  file: yup.mixed().required('File is required'),
  notes: yup.string(),
});

const ResearchPage = () => {
  const navigate = useNavigate();
  const { postRequest, loading, error } = useAxios();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ title: '', message: '', type: '' });

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      student_name: '',
      subject: '',
      student_phone_number: '',
      file: null,
      notes: '',
      due_date: '',
      university_name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const data = new FormData();
      data.append('student_name', values.student_name);
      data.append('subject', values.subject);
      data.append('student_phone_number', values.student_phone_number);
      data.append('file', values.file);
      data.append('notes', values.notes);
      data.append('due_date', values.due_date)
      data.append('university_name', values.university_name)

      try {
        const response = await postRequest('/researches', data);
        if (response) {
          setAlertInfo({ title: 'Success', message: 'Research submitted successfully.', type: 'success' });
          setAlertOpen(true);
          resetForm();
        }
      } catch (err) {
        setAlertInfo({ title: 'Error', message: 'Failed to submit research. Please try again.', type: 'error' });
        setAlertOpen(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFileChange = (event) => {
    formik.setFieldValue('file', event.currentTarget.files[0]);
  };

  return (
    <>
      <Header forceScrolled={true} />
      <div className="research-form-container">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} px={1} className="responsive-box">
          <Typography variant="h4" className="page-title">
            Research & Projects
          </Typography>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/services/student')}
            className="back-button announcement"
          >
            Back to Services
          </Button>
        </Box>
        <Typography variant="body1" className="introduction-text">
          <div className="my-3 bigger-text">
            Here, you can &nbsp;
            <strong>
              upload your research or projects &nbsp;
            </strong>
            for expert review and feedback. <br />
          </div>
          Please fill out the form below with accurate details. <br />
          <em>
            Attach the relevant file if any, <br />
          </em>
          and submit your request. We look forward to helping you with your request.
          <br />
          <div className="text-center my-3 bigger-text">
            <span className="highlight">
              Thank you for your submission!
            </span>
          </div>
        </Typography>
        <form className="research-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledTextField
                label="Student Name"
                name="student_name"
                value={formik.values.student_name}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.student_name && Boolean(formik.errors.student_name)}
                helperText={formik.touched.student_name && formik.errors.student_name}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Student Phone Number"
                name="student_phone_number"
                value={formik.values.student_phone_number}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.student_phone_number && Boolean(formik.errors.student_phone_number)}
                helperText={formik.touched.student_phone_number && formik.errors.student_phone_number}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="University/School Name"
                name="university_name"
                value={formik.values.university_name}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.university_name && Boolean(formik.errors.university_name)}
                helperText={formik.touched.university_name && formik.errors.university_name}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Subject"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
            <CustomDateTextField
              label="Due Date"
              name="due_date"
              type="date"
              value={formik.values.due_date}
              onChange={formik.handleChange}
              fullWidth
              required
              error={formik.touched.due_date && Boolean(formik.errors.due_date)}
              helperText={formik.touched.due_date && formik.errors.due_date}
              placeholder=''
            />

            </Grid>
            <Grid item xs={12}>
              <div className="file-upload-wrapper">
                <Button
                  variant="outlined"
                  component="label"
                  className="file-upload-button"
                  startIcon={<UploadFile />}
                >
                  Upload File
                  <input
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    hidden
                    required
                  />
                </Button>
                {formik.values.file && (
                  <Typography variant="body2" className="file-name">
                    {formik.values.file.name}
                  </Typography>
                )}
              </div>
              {formik.touched.file && formik.errors.file && (
                <Typography color="error" variant="body2" className="error-text">
                  {formik.errors.file}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Notes"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                className="research-submit-button announcement"
                fullWidth
                disabled={loading || formik.isSubmitting}
              >
                {loading || formik.isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <p className="error-text">{error}</p>
              </Grid>
            )}
          </Grid>
        </form>
        <AlertModal
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          title={alertInfo.title}
          message={alertInfo.message}
          type={alertInfo.type}
        />
      </div>
    </>
  );
};

export default ResearchPage;
