// src/pages/auth/AuthPage.jsx
import React, { useState, useContext } from 'react';
import { Container, Typography } from '@mui/material';
import LoginForm from '../../components/auth/loginForm/LoginForm';
import RegisterForm from '../../components/auth/registerForm/RegisterForm';
import ForgotPasswordForm from '../../components/auth/forgotPasswordForm/ForgotPasswordForm';
import Header from '../../components/common/header/Header';
import './AuthPage.css';
import { AuthContext } from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthPage = () => {
  const [currentForm, setCurrentForm] = useState('login');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } }; 

  const handleSwitchToRegister = () => setCurrentForm('register');
  const handleSwitchToLogin = () => setCurrentForm('login');
  const handleSwitchToForgotPassword = () => setCurrentForm('forgotPassword');

  return (
    <div>
      <Header forceScrolled={true} />
      <Container className="auth-page">
        <Typography variant="h4" className="auth-title announcement">
          {currentForm === 'login' ? 'Sign in' : currentForm === 'register' ? 'New User' : currentForm === 'forgotPassword' ? 'Password Reset' : ''}
        </Typography>
        <Typography variant="h4" className="auth-subtitle">
          {currentForm === 'login' ? 'Welcome back! Please enter your details' : currentForm === 'register' ? '' : ''}
        </Typography>
        {currentForm === 'login' && (
          <LoginForm
            handleSwitchToRegister={handleSwitchToRegister}
            handleSwitchToForgotPassword={handleSwitchToForgotPassword}
            login={login}
            onLoginSuccess={() => navigate(from)}
          />
        )}
        {currentForm === 'register' && <RegisterForm handleSwitchToLogin={handleSwitchToLogin} />}
        {currentForm === 'forgotPassword' && <ForgotPasswordForm handleSwitchToLogin={handleSwitchToLogin} />}
      </Container>
    </div>
  );
};

export default AuthPage;
