// src/components/services/studentServices/otherServices/OtherServices.jsx
import React from 'react';
import { Grid, Typography } from '@mui/material';
import CourseCard from '../../../common/courseCard/CourseCard';
import './OtherServices.css';
import Icon from '../../../../assets/images/course.png';
import UniversityIcon from '../../../../assets/images/request.jpg';
import ProjectIcon from '../../../../assets/images/research.webp';
import { useNavigate } from 'react-router-dom';
const otherServices = [
  {
    name: 'Research & Projects',
    description: 'Upload your research or projects for our expert review and feedback.',
    icon: ProjectIcon,
    link: '/services/student/research'
  },
  {
    name: 'University Requests',
    description: 'Connect with your desired university for admissions, information, or collaboration opportunities.',
    icon: UniversityIcon,
    link: '/services/student/requests'
  },
];

const OtherServices = () => {
  const navigate = useNavigate()
  
  const handleOnClick = (link) => {
    navigate(link)
  }

  return (
    <div className="other-services-container">
      <div className="page-intro">
        <Typography variant="h4" className="list-title announcement mb-2">Other Services</Typography>
      </div>

      <div className="service-cards">
            {otherServices.map((service, index) => (
                <div key={index} className="service-card">
                    <CourseCard image={service.icon} course={service} onClick={() => handleOnClick(service.link)} isCentered={true}/>
                </div>
            ))}
      </div>
    </div>
  );
};

export default OtherServices;
