import React from 'react';
import './TeacherServicesPage.css';
import Header from '../../../components/common/header/Header';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import TeacherServicesList from '../../../assets/data/TeacherServicesList';

const TeacherServicesPage = () => {

    return (
        <>
            <Header forceScrolled={true} />
            <Container className="teacher-services-container">
                <Typography variant="h4" className="teacher-services-title">Teacher Services</Typography>
                <Container className="teacher-services-description">
                    <Typography variant="body1" gutterBottom className="announcement page-description">
                        An educational consultancy for teachers can offer a wide range of services designed to support and enhance their professional development, career progression, and teaching effectiveness. Here are some key services that such a consultancy might provide:
                    </Typography>
                    <List>
                        {TeacherServicesList.map((service, index) => (
                            <ListItem key={index} className="teacher-service-item">
                                <ListItemText
                                    primary={<strong className="announcement">{service.title}</strong>}
                                    secondary={service.description}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Typography variant="body1" gutterBottom className="announcement page-footer-text">
                        These services can help teachers at various stages of their careers, whether they are just starting out or looking to advance into leadership roles.
                    </Typography>
                </Container>
            </Container>
        </>
    );
};

export default TeacherServicesPage;
