import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import './AlertModal.css';

const AlertModal = ({ open, onClose, title, message, type }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={`alert-modal-container ${type}`}>
        <Typography variant="h6" className="alert-modal-title announcement">
          {title}
        </Typography>
        <Typography variant="body1" className="alert-modal-message announcement">
          {message}
        </Typography>
        <Button onClick={onClose} className="alert-modal-button announcement">
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
};

export default AlertModal;
