import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAxios from '../../../../hooks/useAxios';
import './UniversityRequestsPage.css'; // Reuse the same styling
import Header from '../../../../components/common/header/Header';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../../../components/common/alertModal/AlertModal';
import StyledTextField from '../../../../components/common/styledComponents/StyledTextField';

// Define the validation schema using yup
const validationSchema = yup.object({
  student_name: yup.string().required('Student Name is required'),
  student_phone_number: yup.string().required('Student Phone Number is required'),
  student_email: yup.string().email('Invalid email address').required('Student Email is required'),
  university_name: yup.string(),
  discipline: yup.string().required('Discipline is required'),
});

const UniversityRequestsPage = () => {
  const navigate = useNavigate();
  const { postRequest, loading, error } = useAxios();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ title: '', message: '', type: '' });

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      student_name: '',
      student_phone_number: '',
      student_email: '',
      university_name: '',
      discipline: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await postRequest('/university-requests', values);
        if (response) {
          setAlertInfo({ title: 'Success', message: 'University request created successfully.', type: 'success' });
          setAlertOpen(true);
          resetForm();
        }
      } catch (err) {
        setAlertInfo({ title: 'Error', message: 'Failed to create university request. Please try again.', type: 'error' });
        setAlertOpen(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Header forceScrolled={true} />
      <div className="requests-form-container">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} className="responsive-box">
          <Typography variant="h4" className="page-title">
            University Requests
          </Typography>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/services/student')}
            className="back-button announcement"
          >
            Back to Services
          </Button>
        </Box>
        <Typography variant="body1" className="introduction-text">
          <div className="my-3 bigger-text">
            Here, you can &nbsp;
            <strong>
              submit your university request &nbsp;
            </strong>
            for expert guidance and support. <br />
          </div>
          Fill out the form below with the required details and &nbsp;
          <em>
            get started on your academic journey. <br />
          </em>
          Our team is here to support your aspirations and academic needs! <br />
          <div className="text-center my-3 bigger-text">
            <span className="highlight">
              We're excited to work with you!
            </span>
          </div>
        </Typography>

        <form className="requests-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledTextField
                label="Student Name"
                name="student_name"
                value={formik.values.student_name}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.student_name && Boolean(formik.errors.student_name)}
                helperText={formik.touched.student_name && formik.errors.student_name}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Student Phone Number"
                name="student_phone_number"
                value={formik.values.student_phone_number}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.student_phone_number && Boolean(formik.errors.student_phone_number)}
                helperText={formik.touched.student_phone_number && formik.errors.student_phone_number}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Student Email"
                name="student_email"
                value={formik.values.student_email}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.student_email && Boolean(formik.errors.student_email)}
                helperText={formik.touched.student_email && formik.errors.student_email}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="University Name"
                name="university_name"
                value={formik.values.university_name}
                onChange={formik.handleChange}
                fullWidth
                // required
                error={formik.touched.university_name && Boolean(formik.errors.university_name)}
                helperText={formik.touched.university_name && formik.errors.university_name}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Major"
                name="discipline"
                value={formik.values.discipline}
                onChange={formik.handleChange}
                fullWidth
                required
                error={formik.touched.discipline && Boolean(formik.errors.discipline)}
                helperText={formik.touched.discipline && formik.errors.discipline}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                className="requests-submit-button announcement"
                fullWidth
                disabled={loading || formik.isSubmitting}
              >
                {loading || formik.isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <p className="error-text">{error}</p>
              </Grid>
            )}
          </Grid>
        </form>
        <AlertModal
            open={alertOpen}
            onClose={() => setAlertOpen(false)}
            title={alertInfo.title}
            message={alertInfo.message}
            type={alertInfo.type}
        />
      </div>
    </>
  );
};

export default UniversityRequestsPage;
