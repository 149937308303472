import React from 'react';
import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& label': {
    fontFamily: 'BuenosAires, sans-serif',
  },
  '& label.Mui-focused': {
    color: 'var(--dark-purple)',
    fontFamily: 'BuenosAires, sans-serif',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'var(--dark-purple)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderRadius: '12px',
    },
    '&:hover fieldset': {
      borderColor: 'var(--dark-purple)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--dark-purple)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'BuenosAires, sans-serif',
  },
});


export default StyledTextField;
