import React from 'react';
import { Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import CourseCard from '../../common/courseCard/CourseCard';
import './ServicesSection.css';
import ProjectsIcon from '../../../assets/images/research.webp';
import UniversityIcon from '../../../assets/images/request.jpg';

const ServicesSection = ({ courses, settings }) => {
  const navigate = useNavigate();

  const otherServices = [
    {
      name: 'Projects & Research',
      image: ProjectsIcon, 
      onClick: () => navigate('/services/student/research'),
    },
    {
      name: 'University Requests',
      image: UniversityIcon,
      onClick: () => navigate('/services/student/requests'),
    }
  ];

  const allCourses = [...courses, ...otherServices];

  return (
    <div className="services-section">
      <Typography variant="h4" className="services-title announcement">Student's Services</Typography>
      <div className="services-description-container">
        <Typography variant="body1" className="services-description">
          Are you ready to take your skills to the next level? <span className="highlight">Join our expertly designed 12-hour Training course</span> for just 1000 AED and gain the knowledge you need to excel in your field.
          Don't miss this opportunity to invest in your future. <span className="highlight">Limited seats are available</span>—register now and start your journey toward success!
        </Typography>
      </div>
      <Slider {...settings} className="services-slider">
        {allCourses.map((course, index) => (
          <div key={index} className="slide">
            <CourseCard
              isCentered={true}
              course={course}
              image={course.image}
              onClick={course.onClick ? course.onClick : () => navigate('/services/student', { state: { initialCategory: course, targetRef: 'targetRef' } })}
            />
          </div>
        ))}
      </Slider>
      <Button
        variant="contained"
        className="get-started-button announcement"
        endIcon={<ArrowForwardIcon />}
        component={Link}
        to="/services/student"
      >
        Get Started
      </Button>
    </div>
  );
};

export default ServicesSection;
