import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const NextArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style, display: 'block', right: 0, color: '#270373' }} onClick={onClick}>
    <ArrowForwardIosIcon />
  </div>
);

const PrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style, display: 'block', left: 0, color: '#270373'  }} onClick={onClick}>
    <ArrowBackIosIcon />
  </div>
);

export { NextArrow, PrevArrow };
