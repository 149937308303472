import React from 'react';
import './CoverImage.css';

const CoverImage = ({ image }) => {
  return (
    <div className="cover-image-container" style={{ backgroundImage: `url(${image})` }}>
      {/* You can add any additional overlay content here */}
    </div>
  );
};

export default CoverImage;
