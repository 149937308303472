// src/components/studentServices/courseList/CourseList.jsx
import React, { useContext, useState } from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import Slider from 'react-slick';
import CourseCard from '../../../common/courseCard/CourseCard';
import CourseDetailsModal from '../../../common/courseDetailsModal/CourseDetailsModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './CourseList.css';
import { NextArrow, PrevArrow } from '../../../common/CustomArrows/CustomArrows';
import { AuthContext } from '../../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const CourseList = ({ category, onBackClick }) => {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleCourseClick = (course) => {
        if (isLoggedIn) {
            setSelectedCourse(course);
        } else {
            // Save the current path and redirect to /auth
            navigate('/auth', { state: { from: location.pathname } });
        }
    };

    const handleCloseModal = () => {
        setSelectedCourse(null);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="course-list">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} className="category-header responsive-box">
                <Typography variant="h4" className="category-title">{category.name}'s Courses</Typography>
                <Button onClick={onBackClick} className="back-button announcement" startIcon={<ArrowBackIcon />}>
                    Back to Categories
                </Button>
            </Box>
            <Typography variant="body1" className="intro-text">
                <div className="intro-header">
                    Ready to &nbsp;
                    <strong>
                    advance your skills? <br />
                    </strong>
                </div>
                Choose your start date for our flexible training course and &nbsp;
                <em>
                    learn at your own pace. <br />
                </em>
                Enroll now and take control of your education! <br />
                <div className="intro-footer">
                    <span className="intro-highlight">
                    Special Offer: Limited Time Only!
                    </span>
                </div>
            </Typography>
            {category.courses.length === 1 ? (
                <Grid container spacing={2} className="courses-container">
                    {category.courses.map((course, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <CourseCard course={course} onClick={() => handleCourseClick(course)} hasButton={true} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Slider {...settings} className="category-slider">
                    
                    {category.courses.map((course, index) => (
                        <div key={index} className="one-class">
                            <CourseCard course={course} onClick={() => handleCourseClick(course)} hasButton={true} />
                        </div>
                    ))}
                </Slider>
            )}
            {/* <Slider {...settings} className="category-slider">
                {category.courses.map((course, index) => (
                <div key={index} className="slide">
                    <CourseCard course={course} onClick={() => handleCourseClick(course)} />
                </div>
                ))}
            </Slider> */}
            {selectedCourse && (
                <CourseDetailsModal course={selectedCourse} open={!!selectedCourse} onClose={handleCloseModal} />
            )}
            
        </div>
    );
};

export default CourseList;
