import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import './Footer.css';
import Logo from '../../../assets/images/logo.png';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container className="footer-container">
        <Box className="footer-links">
          <Typography variant="h6" className="footer-link-header">Get in Touch</Typography>
            <Box className="footer-contact">
              <PhoneIcon className="footer-icon" />
              <Typography variant="body2">00971509910248</Typography>
            </Box>
            <Box className="footer-contact">
              <WhatsAppIcon className="footer-icon" />
              <Typography variant="body2">00971509910248</Typography>
            </Box>
            <Box className="footer-contact">
              <EmailIcon className="footer-icon" />
              <Typography variant="body2">admin@edu-waves.com</Typography>
            </Box>
            <Box className="footer-contact">
              <InstagramIcon className="footer-icon" />
              <Typography variant="body2">@eduwaves_2024</Typography>
            </Box>
        </Box>
        <Box  className="footer-logo-container">
          <img src={Logo} alt="Eduwaves Logo" className="footer-logo" />
          <Typography variant="body2" className="footer-copyright">
            © {currentYear} Eduwaves. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
